var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"biaodansj"},[_c('div',{staticClass:"headNav"},[_c('p',[_c('router-link',{attrs:{"to":"/public"}},[_c('span',{on:{"click":function($event){return _vm.deketeSess()}}},[_vm._v("返回")])])],1),_c('ul',_vm._l((_vm.text),function(item,index){return _c('li',{key:index,style:(_vm.moren == index ? 'background:#EB493B;' : ''),on:{"click":function($event){return _vm.tabTitle(index)}}},[_c('span',{style:({
            background: _vm.moren == index ? '#fff' : '',
            color: _vm.moren == index ? '#f96c6c' : '',
          })},[_vm._v(_vm._s(index + 1))]),_vm._v(" "+_vm._s(item.name)+" ")])}),0),_c('div',[_c('router-link',{attrs:{"to":"/public"}},[_c('p',{staticClass:"typeBtnFalse",on:{"click":function($event){return _vm.deketeSess()}}},[_vm._v("取消")])]),_c('p',{staticClass:"typeBtnTrue",on:{"click":function($event){return _vm.holdFun()}}},[_vm._v("保存")])],1)]),(_vm.moren == 0)?_c('div',{staticClass:"appbg"},[_c('div',[_c('div',[_vm._m(0),_c('div',{staticStyle:{"padding-top":"20px"}},[_c('el-input',{attrs:{"placeholder":"请输入审批名称","size":"medium","maxlength":"50"},model:{value:(_vm.approvalName),callback:function ($$v) {_vm.approvalName=$$v},expression:"approvalName"}})],1)]),_c('div',{staticStyle:{"padding-top":"20px"}},[_vm._m(1),_c('div',{staticStyle:{"padding-top":"20px"}},[_c('el-button',{staticClass:"btnSpan",staticStyle:{"width":"675px","height":"40px","border":"1px solid #dcdfe6","color":"#dcdfe6","text-align":"left","text-indent":"1em","overflow":"hidden","text-overflow":"ellipsis","white-space":"nowrap"},attrs:{"type":"text","size":"medium","maxlength":"50"},on:{"click":function($event){_vm.dialogVisible = true}},model:{value:(_vm.approvalManagement),callback:function ($$v) {_vm.approvalManagement=$$v},expression:"approvalManagement"}},[_vm._l((_vm.checkop),function(i,index){return _c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkop.length),expression:"checkop.length"}],key:index,style:({
                color: _vm.checkop.length ? '#000' : '#dcdfe6',
                display: 'inline',
              })},[_vm._v(_vm._s(i + ','))])}),(!_vm.checkop.length)?_c('span',[_vm._v("请输入管理员")]):_vm._e()],2)],1)]),_c('c-tree',{attrs:{"dialogVisible":_vm.dialogVisible,"drtjData":_vm.drtjData,"jiaose":""},on:{"update:dialogVisible":function($event){_vm.dialogVisible=$event},"update:dialog-visible":function($event){_vm.dialogVisible=$event},"changeDialog":_vm.changeDialog}}),_c('div',{staticStyle:{"padding-top":"20px"}},[_vm._m(2),_c('div',{staticStyle:{"padding-top":"20px"}},[_c('el-input',{attrs:{"placeholder":"请输入审批说明","size":"medium","maxlength":"50"},model:{value:(_vm.approvalInstructions),callback:function ($$v) {_vm.approvalInstructions=$$v},expression:"approvalInstructions"}})],1)]),_c('div',{staticStyle:{"padding-top":"20px"}},[_vm._m(3),_c('div',{staticStyle:{"padding-top":"20px"}},[_c('el-select',{attrs:{"placeholder":"请选择分组","size":"medium","maxlength":"50"},model:{value:(_vm.grouping),callback:function ($$v) {_vm.grouping=$$v},expression:"grouping"}},_vm._l((_vm.options),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.flow_group_name,"value":item.flow_group_id}})}),1)],1)]),_c('div',{staticClass:"iconbox"},[_vm._m(4),_c('div',{staticClass:"fieldblock"},[_c('div',{staticClass:"wf-iconselect"},_vm._l((_vm.icons),function(item,index){return _c('img',{key:index,style:({
                border:
                  item.value == _vm.iconUrl
                    ? '1px solid #3f9af9'
                    : '1px solid #ccc',
              }),attrs:{"src":item.value},on:{"click":function($event){return _vm.iconFun(index)}}})}),0)])])],1)]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.moren == 1),expression:"moren == 1"}]},[_c('ApprovalFormForm')],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]),_vm._v("审批名称 "),_c('span',{staticStyle:{"color":"#cccccc","padding-left":"10px"}},[_vm._v("最多50字")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_vm._v(" 谁可以管理这个模板并导出数据 "),_c('span',{staticStyle:{"color":"#cccccc","padding-left":"10px"}},[_vm._v("只有模版管理员可以编辑这个审批单和导出数据")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_vm._v(" 审批说明 "),_c('span',{staticStyle:{"color":"#cccccc","padding-left":"30px"}},[_vm._v("最多50字")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]),_vm._v("选择分组")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"tit"},[_c('span',{staticStyle:{"color":"red","display":"inline"}},[_vm._v("*")]),_vm._v(" 图标设置 ")])}]

export { render, staticRenderFns }