  <template>
    <div class="wf-form wf-widgetsettings">
      <div v-show="supportSetting">
        <span>控件类型</span>
        <span>&nbsp;&nbsp;{{ supportSetting.name }}</span>
      </div>
      <br />
      <div v-if="supportSetting.label" class="wf-field wf-setting-label">
        <div class="fieldname">
          <span>标题</span>
          <span class="fieldinfo">最多8个字</span>
        </div>
        <div class="fieldblock">
          <input
            type="text"
            @input="changeComponent"
            maxlength="8"
            v-model="supportSetting.defaultLable"
          />
        </div>
      </div>
      <div v-if="supportSetting.label1" class="wf-field wf-setting-label">
        <div class="fieldname">
          <span>标题1</span>
          <span class="fieldinfo">最多8个字</span>
        </div>
        <div class="fieldblock">
          <input
            type="text"
            @input="changeComponent"
            maxlength="8"
            v-model="supportSetting.defaultLable1"
          />
        </div>
      </div>
      <div v-if="supportSetting.label2" class="wf-field wf-setting-label">
        <div class="fieldname">
          <span>标题2</span>
          <span class="fieldinfo">最多8个字</span>
        </div>
        <div class="fieldblock">
          <input
            type="text"
            @input="changeComponent"
            maxlength="8"
            v-model="supportSetting.defaultLable2"
          />
        </div>
      </div>
      <div v-if="supportSetting.textnote" class="wf-field wf-setting-content">
        <div class="fieldname">
          <span>说明文字</span>
          <span class="fieldinfo">最多5000个字</span>
        </div>
        <div class="fieldblock">
          <textarea
            type="text"
            @input="changeComponent"
            maxlength="5000"
            v-model="supportSetting.defaultProps"
          >
                      请输入说明文字
                  </textarea
          >
        </div>
      </div>
      <div
        v-if="supportSetting.placeholder1"
        class="wf-field wf-setting-placeholder"
      >
        <div class="fieldname">
          <span>提示文字</span>
          <span class="fieldinfo">最多12个字</span>
        </div>
        <div class="fieldblock">
          <input
            type="text"
            @input="changeComponent"
            maxlength="12"
            v-model="supportSetting.defaultProps1"
          />
        </div>
      </div>
      <!-- // earl 2021-09-06 新添加字段设置 -->
      <div
      v-if="supportSetting.keys"
      class="wf-field wf-setting-placeholder"
    >
      <div class="fieldname">
        <span>字段名</span>
        <span class="fieldinfo">英文数字下划线组成，首字母必须为英文字母</span>
      </div>
      <div class="fieldblock">
        <input
          type="text"
          @input="changeComponent"
          maxlength="12"
          v-model="supportSetting.keyName"
        />
      </div>
    </div>
      <div
        v-if="supportSetting.placeholder2"
        class="wf-field wf-setting-placeholder"
      >
        <div class="fieldname">
          <span>提示文字</span>
          <span class="fieldinfo">最多12个字</span>
        </div>
        <div class="fieldblock">
          <input
            type="text"
            @input="changeComponent"
            maxlength="12"
            v-model="supportSetting.defaultProps2"
          />
        </div>
      </div>
      <div
        v-if="supportSetting.dateformat"
        class="wf-field wf-setting-placeholder"
      >
        <div class="fieldname">
          <span>日期类型</span>
        </div>
        <div class="fieldname">
          <label class="fieldblock">
            <input
              type="radio"
              name="dateformat"
              v-model="supportSetting.defaultFormat"
              @change="changeComponent"
              value="yyyy-MM-dd HH:mm"
            />
            <span class="verticalmiddle">年-月-日 时:分</span>
          </label>
          <label class="fieldblock">
            <input
              type="radio"
              name="dateformat"
              v-model="supportSetting.defaultFormat"
              @change="changeComponent"
              value="yyyy-MM-dd"
            />
            <span class="verticalmiddle">年-月-日</span>
          </label>
        </div>
      </div>
      <div v-if="supportSetting.uint" class="wf-field wf-setting-placeholder">
        <div class="fieldname">
          <span>单位</span>
          <span class="fieldinfo">最多8个字</span>
        </div>
        <div class="fieldblock">
          <input
            type="text"
            @input="changeComponent"
            v-model="supportSetting.defaulUint"
          />
        </div>
      </div>
      <div
        v-if="supportSetting.guanlian_liebiao"
        class="wf-field wf-setting-placeholder"
      >
        <div class="fieldname">
          <span>指定关联审批单类型</span>
        </div>
        <div class="box_zhi_waimian" @click="dakai_biaodan(supportSetting)">
          <span
            class="box_left"
            style="line-height: 20px"
            v-if="supportSetting.guanlian_liebiao_list.length == 0"
            >请选择</span
          >
          <ul>
            <li
              v-for="(i, index) in supportSetting.guanlian_liebiao_list"
              style="line-height: 20px"
              :key="index"
            >
              {{ i.flow_name }}&nbsp;&nbsp;
            </li>
          </ul>
        </div>
        <p style="margin-top: 10px">不设置时默认可选择所有任意类型的审批单</p>
      </div>
      <div
        v-if="supportSetting.zidingyi == 0"
        class="wf-field wf-setting-options"
      >
        <div class="fieldname">
          <span>选项</span>
          <span class="fieldinfo">最多200项,每项最多5个字</span>
        </div>
        <div
          v-bind:class="{
            limitdel: supportSetting.defaultOptions.length <= 1,
            limitadd: supportSetting.defaultOptions.length >= 200,
          }"
        >
          <div
            v-for="(n, index) in supportSetting.defaultOptions"
            :key="index"
            class="fieldblock wf-setting-options"
          >
            <input type="text" maxlength="8" v-model="n.text" />
            <a @click="del" v-bind:data-index="index" class="action action-del">
              <i class="icon icon-minus"></i>
            </a>
            <a @click="add" v-bind:data-index="index" class="action action-add">
              <i class="icon icon-plus"></i>
            </a>
          </div>
        </div>
      </div>
      <div v-if="supportSetting.important" class="wf-field wf-setting-required">
        <div class="fieldname">验证</div>
        <label class="fieldblock">
          <input
            type="checkbox"
            @change="changeComponent"
            value="1"
            v-model="supportSetting.defaultImportant"
          />
          <span class="verticalmiddle">必填</span>
        </label>
      </div>
      <div v-if="supportSetting.Enable && this.info" class="wf-field wf-setting-required">
        <div class="fieldname">启用(公共表单)</div>
        <label class="fieldblock">
          <input
            type="checkbox"
            @change="changeComponent"
            value="1"
            v-model="supportSetting.defaultEnable"
          />
          <span class="verticalmiddle">停用</span>
        </label>
      </div>
      <!-- <div v-if="supportSetting.translate" class="wf-field wf-setting-conformat">
              <div class="fieldname">大写</div>
              <label class="fieldblock">
                  <input type="checkbox" @change="changeComponent" value="1"
                        v-model="supportSetting.defaultTranslate">
                  <span class="verticalmiddle">显示大写</span>
                  <span class="verticalmiddle fieldinfo">（数字输入后自动显示大写）</span>
              </label>
          </div> -->
      <!-- <div v-if="supportSetting.autorekonTime" class="wf-field-group">
              <div class="wf-field">
                  <div class="fieldname">自动计算时长</div>
              </div>
              <div class="wf-field wf-setting-duration">
                  <label class="fieldblock">
                      <input type="checkbox" @change="changeComponent" value="1"
                            v-model="supportSetting.defaultAutorekonTime">
                      <span class="verticalmiddle">开启</span>
                  </label>
              </div>
              <div class="wf-setting-duration-label" v-if="supportSetting.defaultAutorekonTime">
                  <div class="wf-field wf-setting-label">
                      <div class="fieldname">
                          <span>标题</span>
                          <span class="fieldinfo">最多12个字</span>
                      </div>
                      <div class="fieldblock">
                          <input type="text" maxlength="12" v-model="supportSetting.defaultSubtitle">
                      </div>
                  </div>
              </div>

          </div> -->
      <!-- <div v-if="supportSetting.sync" class="wf-field wf-setting-required">
              <div class="fieldname">同步到考勤</div>
              <label class="fieldblock">
                  <input type="checkbox" @change="changeComponent" value="1"
                        v-model="supportSetting.defaultSync">
                  <span class="verticalmiddle">开启</span>
              </label>
          </div> -->
      <!-- <div v-if="supportSetting.radio" class="wf-field wf-setting-required">
              <div class="fieldname"><span>选项</span></div>
              <div class="fieldname">
                  <label class="fieldblock">
                      <input type="radio" @change="changeComponent" name="xuanxiang" v-model="supportSetting.dan_duoxuan" value="true">
                      <span class="verticalmiddle">只能选择一个部门</span>
                  </label>
                  <label class="fieldblock">
                      <input type="radio" @change="changeComponent" name="xuanxiang" v-model="supportSetting.dan_duoxuan" value='false'>
                      <span class="verticalmiddle">可同时选择多个部门</span>
                  </label>
              </div>
          </div> -->
      <!-- <div v-if="supportSetting.radio2" class="wf-field wf-setting-required">
              <div class="fieldname"><span>选项</span></div>
              <div class="fieldname">
                  <label class="fieldblock">
                      <input type="radio" @change="changeComponent" name="xuanxiang" v-model="supportSetting.dan_duoxuan" value="true">
                      <span class="verticalmiddle">只能选择一个产品</span>
                  </label>
                  <label class="fieldblock">
                      <input type="radio" @change="changeComponent" name="xuanxiang" v-model="supportSetting.dan_duoxuan" value='false'>
                      <span class="verticalmiddle">可同时选择多个产品</span>
                  </label>
              </div>
          </div> -->
      <div v-if="supportSetting.gongshi2" class="wf-field wf-setting-required">
        <div class="fieldname"><span>计算公式</span></div>
        <div></div>
        <div class="box_zhi_waimian" @click="dakai_gongshi(supportSetting)">
          <span class="box_left" style="line-height: 20px">计算公式=</span>
          <ul>
            <li
              v-for="(i, index) in gongshi_yangzi"
              :key="index"
              :style="
                i.zhi != 'shuzi' && i.zhi != 'jisuan'
                  ? 'padding: 4px 4px;border-radius: 2px;color: rgba(0,0,0,.65);background: #f5f5f5;'
                  : 'line-height:20px;'
              "
            >
              {{ i.name }}
            </li>
          </ul>
        </div>
        <p style="font-size: 14px; line-height: 20px">
          编辑计算公式可用来完成审批单内数据的自动结算，例如：采购单内设置计算公式“合计=单价×数量”，发起人填写单价、数量后，组件将自动计算出合计金额，免手动计算。
        </p>
      </div>
      <!-- <div v-if="supportSetting.radio3" class="wf-field wf-setting-required">
              <div class="fieldname"><span>选项</span></div>
              <div class="fieldname">
                  <label class="fieldblock">
                      <input type="radio" @change="changeComponent" name="xuanxiang" v-model="supportSetting.dan_duoxuan" value="true">
                      <span class="verticalmiddle">只能选择一个人</span>
                  </label>
                  <label class="fieldblock">
                      <input type="radio" @change="changeComponent" name="xuanxiang" v-model="supportSetting.dan_duoxuan" value='false'>
                      <span class="verticalmiddle">可同时选择多个人</span>
                  </label>
              </div>
          </div> -->
      <!-- <div v-if="supportSetting.print" class="wf-field wf-setting-print">
              <div class="fieldname">打印</div>
              <label class="fieldblock">
                  <input type="checkbox"  @change="changeComponent" value="1" v-model="supportSetting.defaultPrint">
                  <span class="verticalmiddle">参与打印</span>
                  <span class="verticalmiddle">（如不勾选打印时，不显示此项）</span>
              </label>
          </div> -->
      <div class="beijing" v-if="dakai">
        <div class="box1">
          <div class="box1_tit">
            编辑计算公式
            <i class="el-icon-close" @click="dakai = false"></i>
          </div>
          <div class="box_con">
            <div class="box_jinggao" v-if="gongshi_youwenti">
              编辑的公式不符合计算法则，无法计算
              <i class="el-icon-close" @click="gongshi_youwenti = false"></i>
            </div>
            <div
              class="box_zhi"
              :style="gongshi_youwenti ? 'border:1px solid #f28172' : ''"
            >
              <span class="box_left" style="line-height: 20px">计算公式=</span>
              <span
                class="tishi"
                style="line-height: 20px"
                v-if="yunzai.length == 0"
                >请在下方选择计算对象和计算符号完成编辑</span
              >
              <ul v-if="yunzai.length != 0">
                <li
                  v-for="(i, index) in yunzai"
                  :style="
                    i.zhi != 'shuzi' && i.zhi != 'jisuan'
                      ? 'border:1px solid #cacaca;background:rgb(241, 241, 241);padding:3px 6px;display: inline-block;height:20px;'
                      : 'line-height:20px;'
                  "
                  :key="index"
                >
                  {{ i.name }}
                </li>
              </ul>
              <div class="bottom">
                <i @click="quchu_yige" class="el-icon-back"></i>
                <span @click="yunzai = []">清空</span>
              </div>
            </div>
            <p class="box_tishi">
              编辑计算公式可用来完成审批单内数据的自动结算，例如：采购单内设置计算公式“合计=单价×数量”，发起人填写单价、数量后，组件将自动计算出合计金额，免手动计算。
            </p>
            <div class="box_jisuan_duixiang">
              <span>计算对象：</span>
              <ul>
                <li class="kong" v-if="duixiang.length == 0">
                  没有可计算的对象，请返回表单添加
                </li>
                <li
                  v-for="(i, index) in duixiang"
                  @click="jisuan_duixiang(i)"
                  :key="index"
                >
                  {{ i.name }}
                </li>
              </ul>
            </div>
            <div class="box_jisuan_duixiang">
              <span>计算符号：</span>
              <ul>
                <li
                  v-for="(i, index) in fuhao"
                  @click="jisuan_fu(i)"
                  :key="index"
                >
                  {{ i.name }}
                </li>
              </ul>
            </div>
            <div class="box_jisuan_duixiang">
              <span>数字键盘：</span>
              <ul class="jianpan_type">
                <li
                  v-for="(i, index) in jianpan"
                  @click="jisuan_shuzi(i)"
                  :key="index"
                >
                  {{ i.name }}
                </li>
              </ul>
            </div>
          </div>
          <div class="box_foot">
            <span @click="dakai = false">取消</span>
            <span @click="baocun_gongshi">确定</span>
          </div>
        </div>
      </div>
      <div class="beijing" v-if="biaodan">
        <div class="box1">
          <div class="box1_tit">
            指定关联审批单类型
            <i class="el-icon-close" @click="biaodan = false"></i>
          </div>
          <div class="box_con">
            <ul class="ul" v-for="(i, index) in title" :key="index">
              <li @click="i.zhi = !i.zhi">
                <i
                  :class="i.zhi ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"
                  style="margin-right: 5px"
                ></i
                >{{ i.name }}
              </li>
              <li v-for="(ia, inde) in i.children" :key="inde" v-show="i.zhi">
                <p @click="xuanze_leixing(ia)">
                  <span class="tit_left">
                    <img
                      :src="
                        ia.xuanze
                          ? require('@img/shenpi/okblue.png')
                          : require('@img/shenpi/noccc.png')
                      "
                    />
                    {{ ia.flow_name }}</span
                  >
                </p>
              </li>
            </ul>
            <!-- <ul class="ul" v-for="(i,index) in title" :key='index'>
                          <li>{{i.name}}</li>
                          <li v-for="(ia,inde) in i.children" :key="inde">
                              <p class="li_p"><span>{{ia.flow_name}}</span>
                              </p>
                          </li>
                      </ul> -->
          </div>
          <div class="box_foot">
            <span @click="biaodan = false">取消</span>
            <span @click="baocun_biaodan">确定</span>
          </div>
        </div>
      </div>
    </div>
</template>
  <script>
  // import { query_flow_group_info_list, query_flow_info_list } from '../api/api'
  export default {
    inject: ['info', 'fooMethod'],
    data: function() {
      return {
        supportSetting: {},
        dakai: false,
        duixiang: [
          {
            name: '111'
          },
          {
            name: '111'
          },
          {
            name: '111'
          }
        ],
        fuhao: [
          {
            name: '+',
            zhi: 'jisuan'
          },
          {
            name: '-',
            zhi: 'jisuan'
          },
          {
            name: 'x',
            zhi: 'jisuan'
          },
          {
            name: '÷',
            zhi: 'jisuan'
          },
          {
            name: '(',
            zhi: 'jisuan'
          },
          {
            name: ')',
            zhi: 'jisuan'
          }
        ],
        jianpan: [
          {
            name: '1',
            zhi: 'shuzi'
          },
          {
            name: '2',
            zhi: 'shuzi'
          },
          {
            name: '3',
            zhi: 'shuzi'
          },
          {
            name: '4',
            zhi: 'shuzi'
          },
          {
            name: '5',
            zhi: 'shuzi'
          },
          {
            name: '6',
            zhi: 'shuzi'
          },
          {
            name: '7',
            zhi: 'shuzi'
          },
          {
            name: '8',
            zhi: 'shuzi'
          },
          {
            name: '9',
            zhi: 'shuzi'
          },
          {
            name: '0',
            zhi: 'shuzi'
          },
          {
            name: '.',
            zhi: 'shuzi'
          }
        ],
        gongshi_yangzi: [],
        yunzai: [], // 运载计算公式,
        gongshi_youwenti: false,
        biaodan: false,
        title: [],
        zhanshi: false
      }
    },
    watch: {
      // yunzai:{
      //     handler(newValue, oldValue) {
      //         this.bianji_jisuan_gongshi()
      //     },
      //     deep: true
      // },
      dakai() {
        if (this.dakai == false) {
          this.yunzai = []
        }
      }
    },
    methods: {
      add: function(e) {
        e.stopPropagation()
        e.preventDefault()
        const index = e.currentTarget.getAttribute('data-index')
        for (
          let i = 0, l = this.supportSetting.defaultOptions.length;
          i < l;
          i++
        ) {
          let has = false
          for (const item in this.supportSetting.defaultOptions) {
            if (this.supportSetting.defaultOptions[item].idx == i + 1) {
              has = true
            }
          }
          if (!has) {
            this.supportSetting.defaultOptions.splice(+index + 1, 0, {
              idx: i + 1,
              text: '选项' + (i + 1),
              zhi: false,
              SettlementMethod: null,
              PrOfSettlement: 0,
              Settlement_amt: 0
            })
            return
          }
        }
        if (index == this.supportSetting.defaultOptions.length - 1) {
          this.supportSetting.defaultOptions.push({
            idx: this.supportSetting.defaultOptions.length + 1,
            text: '选项' + (this.supportSetting.defaultOptions.length + 1),
            zhi: false,
            SettlementMethod: null,
            PrOfSettlement: 0,
            Settlement_amt: 0
          })
        } else {
          this.supportSetting.defaultOptions.splice(+index + 1, 0, {
            idx: this.supportSetting.defaultOptions.length + 1,
            text: '选项' + (this.supportSetting.defaultOptions.length + 1),
            zhi: false,
            SettlementMethod: null,
            PrOfSettlement: 0,
            Settlement_amt: 0
          })
        }
      },
      del: function(e) {
        e.stopPropagation()
        e.preventDefault()
        const index = e.currentTarget.getAttribute('data-index')
        this.supportSetting.defaultOptions.splice(index, 1)
      },
      changeComponent: function() {
        console.log(this.supportSetting)
        if (sessionStorage.getItem('mlbb_liucheng_shezhi') != undefined) {
          const date = this.$bendi_naqu_cunchu('mlbb_liucheng_shezhi', '暂时')
          this.liucheng_digui(date, this.supportSetting)
          drag.$emit('changeComponent', this.supportSetting)
          this.$bendi_cunfang_cunchu('mlbb_liucheng_shezhi', date, '暂时')
        } else {
          drag.$emit('changeComponent', this.supportSetting)
        }
      },
      // 没有注释   改的时候  注意点   这块牵扯了流程设置里条件设置的东西
      liucheng_digui(data, date) {
        console.log(data)
        // if(data.type == 1){
        // data.edit_list.forEach(item=>{
        //     if(item.idx==date.idx&&date.defaultImportant){
        //         if(item.type==1){
        //             item.type=2
        //         }
        //     }
        // })
        // if(data.childNode!=null){
        //     this.liucheng_digui(data.childNode,date)
        // }
        // }else
        if (data != undefined) {
          if (
            (data.type == 1 ||
              data.type == 0 ||
              data.type == 2 ||
              data.type == 9) &&
            data.childNode != null
          ) {
            // 审批人
            this.liucheng_digui(data.childNode, date)
          } else if (data.type == 3) {
            for (let i = 0; i < data.conditionList.length; i++) {
              if (
                data.conditionList[i].idx == date.idx &&
                !date.defaultImportant
              ) {
                data.conditionList.splice(i, 1)
                break
              }
            }
            if (data.childNode != null) {
              this.liucheng_digui(data.childNode, date)
            }
          } else if (data.type == 4) {
            if (data.childNode != null) {
              this.liucheng_digui(data.childNode, date)
            }
            for (let i = 0; i < data.conditionNodes.length; i++) {
              this.liucheng_digui(data.conditionNodes[i], date)
            }
          }
        }
      },
      // liucheng_digui(i,date){
      //     if(i.type!=4&&i.childNode!=undefined){
      //         this.liucheng_digui(i.childNode,date)
      //     }else if(i.childNode!=undefined){
      //         this.luyou_digui(i,date)
      //     }
      // },
      // luyou_digui(i,date){
      //     i.conditionNodes.forEach(item=>{
      //         for(let ite=0;ite<item.conditionList.length;ite++){
      //             if(item.conditionList[ite].idx==date.idx&&!date.defaultImportant){
      //                 item.conditionList.splice(ite,1)
      //                 break;
      //             }
      //         }
      //     })
      //     if(i.childNode!=undefined){
      //         this.liucheng_digui(i.childNode,date)
      //     }
      // },
      dakai_gongshi(i) {
        // console.log(i)
        this.dakai = true
        const cunchu_data = this.$bendi_naqu_cunchu('mlbb_biaodan_sheji', '暂时')
        console.log(cunchu_data)
        // numberfield
        this.duixiang = []
        if (i.gongshi.length != 0) {
          this.yunzai = i.gongshi
        }
        if (
          cunchu_data.length != 0 ||
          cunchu_data != undefined ||
          cunchu_data != ''
        ) {
          for (let i = 0; i < cunchu_data.length; i++) {
            if (
              cunchu_data[i].componentName == 'numberfield' ||
              cunchu_data[i].componentName == 'moneyfield' ||
              cunchu_data[i].componentName == 'calcform'
            ) {
              cunchu_data[i].name = cunchu_data[i].defaultLable
              this.duixiang.push(cunchu_data[i])
            }
            if (cunchu_data[i].componentName == 'tablefield') {
              for (let a = 0; a < cunchu_data[i].components.length; a++) {
                if (
                  cunchu_data[i].components[a].componentName == 'numberfield' ||
                  cunchu_data[i].components[a].componentName == 'moneyfield' ||
                  cunchu_data[i].components[a].componentName == 'calcform'
                ) {
                  cunchu_data[i].components[a].name =
                    cunchu_data[i].defaultLable +
                    '.' +
                    cunchu_data[i].components[a].defaultLable
                  this.duixiang.push(cunchu_data[i].components[a])
                }
              }
            }
          }
        }
      },
      dakai_biaodan(i) {
        this.biaodan = true
      },
      xuanze_leixing(i) {
        i.xuanze = !i.xuanze
      },
      baocun_biaodan() {
        const list = []
        for (let i = 0; i < this.title.length; i++) {
          for (let a = 0; a < this.title[i].children.length; a++) {
            if (this.title[i].children[a].xuanze) {
              list.push(this.title[i].children[a])
            }
          }
        }
        // if(list.length==0){
        //     this.$message({
        //         message: '还未进行选择',
        //         type: 'warning'
        //     });
        // }else{
        this.biaodan = false
        this.supportSetting.guanlian_liebiao_list = list
        drag.$emit('changeComponent', this.supportSetting)
        // }
        console.log(list)
      },
      // 点击符号
      jisuan_fu(i) {
        this.yunzai.push(i)
      },
      // 点击数字
      jisuan_shuzi(i) {
        this.yunzai.push(i)
      },
      // 点击计算对象
      jisuan_duixiang(i) {
        this.yunzai.push(i)
      },
      // 去除一个
      quchu_yige() {
        if (this.yunzai.length != 0) {
          const zhi = []
          for (let i = 0; i < this.yunzai.length - 1; i++) {
            zhi.push(this.yunzai[i])
          }
          this.yunzai = zhi
        }
      },
      // 保存公式
      baocun_gongshi() {
        if (this.yunzai.length != 0) {
          const list = []
          for (let i = 1; i < this.yunzai.length; i++) {
            if (
              this.yunzai[i].zhi != 'jisuan' &&
              this.yunzai[i].zhi != 'shuzi' &&
              this.yunzai[i - 1].zhi != 'jisuan' &&
              this.yunzai[i - 1].zhi != 'shuzi'
            ) {
              list.push('111')
            }
            if (
              this.yunzai[i].zhi == 'jisuan' &&
              this.yunzai[i - 1].zhi == 'jisuan'
            ) {
              list.push('111')
            }
            if (this.yunzai[i].zhi != 'jisuan' && this.yunzai[i].zhi != 'shuzi') {
              if (
                this.yunzai[i - 1].zhi == 'shuzi' ||
                this.yunzai[i + 1] == 'shuzi'
              ) {
                list.push(i)
              }
              if (
                this.yunzai[i - 1].zhi == 'jisuan' &&
                this.yunzai[i - 2] == undefined
              ) {
                list.push(i)
              }
            }
            if (this.yunzai[this.yunzai.length - 1].zhi == 'jisuan') {
              list.push(i)
            }
          }
          if (list.length == 0) {
            this.gongshi_yangzi = []
            for (let i = 0; i < this.yunzai.length; i++) {
              this.gongshi_yangzi.push(this.yunzai[i])
            }
            this.dakai = false
            this.supportSetting.gongshi = this.gongshi_yangzi
            drag.$emit('changeComponent', this.supportSetting)
          } else {
            this.gongshi_youwenti = true
          }
        } else {
          this.$message({
            message: '还未编写计算公式',
            type: 'warning'
          })
        }
      }
      // 计算公式展示页面
      // bianji_jisuan_gongshi(){
      //     if(this.yunzai.length!=0){
      //         let zhi=''
      //         for(let i=0;i<this.yunzai.length;i++){
      //             zhi=zhi+this.yunzai[i].name
      //         }
      //         this.gongshi_yangzi_1=zhi
      //     }
      // }
    },

    created: function() {
      const self = this
      drag.$on('selectComponent', function(obj) {
        self.supportSetting = {}
        if (obj.supportSetting != undefined) {
          for (let i = 0; i < obj.supportSetting.length; i++) {
            self.supportSetting[obj.supportSetting[i]] = true
          }
        }
        self.supportSetting = Object.assign({}, self.supportSetting, obj)
        if (self.supportSetting.componentName == 'calcform') {
          self.gongshi_yangzi = self.supportSetting.gongshi
        }
      })
      // query_flow_group_info_list({
      //   data: {
      //     ent_id: this.$ent_id()
      //   }
      // }).then(res => {
      //   console.log(res)
      //   if (res.data.code == 200) {
      //     const date = JSON.parse(res.data.body.data)
      //     console.log(date)
      //     date.forEach(item => {
      //       this.$set(item, 'name', item.flow_group_name)
      //       this.$set(item, 'zhi', false)
      //       item.children.forEach(ite => {
      //         this.$set(ite, 'xuanze', false)
      //       })
      //     })
      //     this.title = date
      //   } else if (res.data.code == 500) {}
      // })
      // query_flow_info_list({data:{
      //     ent_id:this.$ent_id()
      // }}).then(res=>{
      //     if(res.data.code==200){
      //         let date=JSON.parse(res.data.body.data)
      //         console.log(date)
      //         this.title=[
      //             {
      //                 name:'销售',
      //                 children:[]
      //             }
      //         ]
      //         for(let i=0;i<date.length;i++){
      //             if(date[i].flow_group_id==1){
      //                 for(let a=0;a<this.title.length;a++){
      //                     if(this.title[a].name=='销售'){
      //                         date[i]['xuanze']=false
      //                         this.title[a].children.push(date[i])
      //                     }
      //                 }
      //             }
      //         }
      //     }else if(res.data.code==500){}
      // })
    },
    updated: function() {}
  }
  </script>
  <style scoped lang="scss">
  .wf-widgetsettings{
    margin: 30px;
    box-sizing: border-box;
  }
  .box_zhi_waimian{
    min-height:38px;
    width:298px;
    border: 1px solid rgba(0,0,0,.15);
  }
  .box_zhi_waimian {
    min-height: 38px;
    width: 298px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    padding: 6px 10px;
    display: flex;
    justify-content: flex-start;
    .box_left {
      width: 56px;
    }
    ul {
      width: 81%;
      display: flex;
      flex-wrap: wrap;
    }
  }
  .beijing {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    margin: 0;
    z-index: 999;
    background: rgba(0, 0, 0, 0.3);
    .box1 {
      min-height: 520px;
      width: 544px;
      background: #fff;
      border-radius: 3px;
      position: absolute;
      left: 50%;
      margin-left: -272px;
      top: 50%;
      margin-top: -260px;
      .box1_tit {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 24px;
        color: rgba(0, 0, 0, 0.85);
        font-size: 16px;
        border-bottom: 1px solid #ccc;
      }
      .box_con {
        padding: 24px 54px;
        max-height: 400px;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 20px;
          height: 8px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 12px;
          border: 6px solid rgba(0, 0, 0, 0);
          box-shadow: 8px 0 0 #a5adb7 inset;
        }

        &::-webkit-scrollbar-thumb:hover {
          box-shadow: 8px 0 0 #4a4a4a inset;
        }
        .box_jinggao {
          border: 1px solid #f28172;
          padding: 8px 30px 8px 37px;
          margin: 0 auto;
          width: 412px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-radius: 4px;
          margin-bottom: 7px;
          color: rgba(0, 0, 0, 0.85);
          font-size: 14px;
          i {
            cursor: pointer;
          }
        }
        .box_zhi {
          min-height: 92px;
          width: 412px;
          border: 1px solid #eaeaea;
          border-radius: 3px;
          word-wrap: break-word;
          word-break: break-all;
          overflow: hidden;
          padding: 10px;
          margin: 0 auto;
          display: flex;
          justify-content: flex-start;
          position: relative;
          .box_left {
            width: 56px;
          }
          .tishi {
            color: #ccc;
          }
          ul {
            width: 86%;
            display: flex;
            flex-wrap: wrap;
          }
          .bottom {
            position: absolute;
            bottom: 10px;
            right: 20px;
            i {
              border: 1px solid #ccc;
              cursor: pointer;
              margin-right: 10px;
            }
            span {
              color: #f28172;
              cursor: pointer;
            }
          }
        }
        .box_tishi {
          font-size: 12px;
          color: #555;
          margin: 10px 0 5px 0;
          line-height: 18px;
          padding: 0 13px;
        }
        .box_jisuan_duixiang {
          font-size: 12px;
          color: #555;
          padding: 7px 13px;
          display: flex;
          justify-content: flex-start;
          span {
            padding: 7px 0;
            width: 20%;
          }
          ul {
            li {
              padding: 5px 12px;
              display: inline-block;
              cursor: pointer;
              line-height: 1;
              border: 1px solid #eaeaea;
              border-radius: 4px;
              margin-right: 10px;
              margin-bottom: 7px;
              &:hover {
                background: rgb(241, 241, 241);
              }
            }
            .kong {
              color: #ccc;
              padding: 0;
              display: inline-block;
              border: none;
            }
          }
          .jianpan_type {
            width: 40%;
            li {
              margin-bottom: 7px;
            }
          }
        }
        .ul {
          li {
            cursor: pointer;
            &:hover {
              background: #e8e8e8;
            }
            padding: 10px 40px;
            &:nth-child(1) {
              padding: 10px;
            }
          }
        }
      }
      .box_foot {
        padding: 10px 16px;
        text-align: right;
        background: transparent;
        border-top: 1px solid #e8e8e8;
        border-radius: 0 0 4px 4px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        span {
          margin-left: 8px;
          padding: 5px 15px;
          border-radius: 4px;
          border: 1px solid red;
          cursor: pointer;
          &:nth-child(1) {
            font-size: 14px;
            color: #555;
            border: 1px solid #ccc;
          }
          &:nth-child(2) {
            font-size: 14px;
            color: #fff;
            border: 1px solid rgb(24, 144, 255);
            background: rgb(24, 144, 255);
          }
        }
      }
    }
  }
  </style>
