<style scoped>
  .biaodansj {
    height: 100vh;
    background: #f4f7fd;
  }

  .headNav {
    height: 55px;
    width: 100%;
    background: #f96c6c;
    /* rgb(235, 73, 59) */
    position: relative;
  }

  .headNav>p {
    height: 55px;
    line-height: 55px;
    position: absolute;
    left: 30px;
    top: 0;
  }

  .headNav>div {
    height: 55px;
    position: absolute;
    right: 30px;
    top: 3px;
  }

  .headNav>div p {
    height: 50px;
    line-height: 50px;
    display: inline-block;
    padding: 0 30px;
    margin-right: 5px;
    cursor: pointer;
  }

  .headNav>div .typeBtnFalse {
    background: #fff;
    border: 2px solid #fff;
  }

  .headNav>div .typeBtnTrue {
    border: 2px solid #fff;
    color: #fff;
  }

  .headNav>ul {
    width: 100%;
    height: 50px;
    text-align: center;
  }

  .headNav>ul>li {
    color: #fff;
    height: 50px !important;
    line-height: 50px;
    display: inline-block;
    padding: 0 50px;
    height: 50px;
  }

  .headNav>ul>li span {
    width: 20px;
    height: 20px;
    line-height: 18px;
    text-align: center;
    border-radius: 50%;
    border: 1px solid #fff;
    display: inline-block;
  }

  .appbg {
    background: #f4f7fd;
    min-height: 94vh;
    padding-top: 20px;
    display: flex;
    justify-content: center;
  }

  .appbg>div {
    width: 785px;
    height: 693px;
    padding: 22px 55px;
    background: #fff;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    text-align: left;
  }

  .appbg>div span {
    display: flex;
    align-items: center;
  }

  .btnSpan span {
    display: inline !important;
  }

  .el-input {
    width: 656px !important;
  }

  .el-input__inner {
    width: 600px !important;
  }

  .grayBLock {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .grayBLock .choose-box {
    width: 45%;
    height: 354px;
    overflow: auto;
  }

  .grayBLock .sure-box {
    width: 45%;
    height: 354px;
    overflow-y: auto;
    padding-left: 20px;
    background: #e7e8eb;
  }

  .grayBLock .sure-box div {
    padding-top: 20px;
  }

  .el-collapse-item {
    width: 100%;
    height: auto;
  }

  .el-collapse-item__header {
    border-bottom: none;
    padding-left: 20px;
  }

  .el-collapse-item__content {
    background: #e7e8eb;
    border-bottom: none;
    padding-left: 20px;
  }

  .el-checkbox-group {
    display: flex;
    flex-direction: column;
  }

  .iconbox {
    height: 300px;
    margin-top: 20px;
    box-sizing: border-box;
    overflow: hidden;
  }

  .iconbox p {
    line-height: 40px;
  }

  .wf-iconselect {
    width: 100%;
    height: 300px;
    overflow-y: auto;
    padding-bottom: 60px;
  }

  .wf-iconselect img {
    width: 58px;
    height: 58px;
    margin: 5px 12px;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ccc;
    cursor: pointer;
  }
</style>
<template>
  <div class="biaodansj">
    <div class="headNav">
      <p>
        <router-link to="/public"><span @click="deketeSess()">返回</span></router-link>
      </p>
      <ul>
        <li v-for="(item, index) in text" :key="index" @click="tabTitle(index)"
          :style="moren == index ? 'background:#EB493B;' : ''">
          <span :style="{
              background: moren == index ? '#fff' : '',
              color: moren == index ? '#f96c6c' : '',
            }">{{ index + 1 }}</span>
          {{ item.name }}
        </li>
      </ul>
      <div>
        <router-link to="/public">
          <p class="typeBtnFalse" @click="deketeSess()">取消</p>
        </router-link>
        <p @click="holdFun()" class="typeBtnTrue">保存</p>
      </div>
    </div>
    <!-- 基础设计 -->
    <div class="appbg" v-if="moren == 0">
      <div>
        <div>
          <span>
            <span style="color: red">*</span>审批名称
            <span style="color: #cccccc; padding-left: 10px">最多50字</span>
          </span>
          <div style="padding-top: 20px">
            <el-input v-model="approvalName" placeholder="请输入审批名称" size="medium" maxlength="50"></el-input>
          </div>
        </div>
        <div style="padding-top: 20px">
          <span>
            谁可以管理这个模板并导出数据
            <span style="color: #cccccc; padding-left: 10px">只有模版管理员可以编辑这个审批单和导出数据</span>
          </span>
          <div style="padding-top: 20px">
            <el-button class="btnSpan" style="
                width: 675px;
                height: 40px;
                border: 1px solid #dcdfe6;
                color: #dcdfe6;
                text-align: left;
                text-indent: 1em;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              " type="text" @click="dialogVisible = true" v-model="approvalManagement" size="medium" maxlength="50">
              <span :style="{
                  color: checkop.length ? '#000' : '#dcdfe6',
                  display: 'inline',
                }" v-show="checkop.length" v-for="(i, index) in checkop" :key="index">{{ i + ',' }}</span>
              <span v-if="!checkop.length">请输入管理员</span>
            </el-button>
          </div>
        </div>
        <!-- 管理模板导出弹框 -->
        <c-tree :dialogVisible.sync="dialogVisible" @changeDialog="changeDialog" :drtjData="drtjData" jiaose></c-tree>

        <div style="padding-top: 20px">
          <span>
            审批说明
            <span style="color: #cccccc; padding-left: 30px">最多50字</span>
          </span>
          <div style="padding-top: 20px">
            <el-input v-model="approvalInstructions" placeholder="请输入审批说明" size="medium" maxlength="50"></el-input>
          </div>
        </div>
        <div style="padding-top: 20px">
          <span> <span style="color: red">*</span>选择分组</span>
          <div style="padding-top: 20px">
            <el-select v-model="grouping" placeholder="请选择分组" size="medium" maxlength="50">
              <el-option v-for="(item, index) in options" :label="item.flow_group_name" :value="item.flow_group_id"
                :key="index"></el-option>
            </el-select>
          </div>
        </div>
        <div class="iconbox">
          <p class="tit">
            <span style="color: red; display: inline">*</span> 图标设置
          </p>
          <div class="fieldblock">
            <div class="wf-iconselect">
              <img v-for="(item, index) in icons" :key="index" @click="iconFun(index)" :src="item.value" :style="{
                  border:
                    item.value == iconUrl
                      ? '1px solid #3f9af9'
                      : '1px solid #ccc',
                }" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 表单设计 -->
    <div v-show="moren == 1">
      <ApprovalFormForm></ApprovalFormForm>
    </div>
  </div>
</template>
<script>
  import {
    query_manager_of_form
  } from '../../api/shenpi'
  import ApprovalFormForm from '@/components/ApprovalFormForm'
  import {
    insert_flow_info,
    replace_flow_form_info_private,
    query_flow_group_info_list,
    insert_flow_form_info,
    insert_flow_info_public,
  } from '../../api/shenpi.js'
  const info = JSON.parse(sessionStorage.getItem('pc_mlbb_jichuxinxi'))
  export default {
    components: {
      ApprovalFormForm,
    },
    provide() {
      return {
        info: this.info,
        fooMethod: (x) => {
          this.data.name = x
        },
      }
    },
    data() {
      return {
        // 图标设置
        iconUrl: 'https://www.mlcbr.com/images/logo/tb1.png', // 当前url地址
        icons: [{
            value: 'https://www.mlcbr.com/images/logo/tb1.png',
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb2.png',
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb3.png',
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb4.png',
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb5.png',
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb6.png',
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb7.png',
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb8.png',
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb9.png',
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb10.png',
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb11.png',
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb12.png',
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb13.png',
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb14.png',
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb15.png',
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb16.png',
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb17.png',
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb18.png',
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb19.png',
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb20.png',
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb21.png',
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb22.png',
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb23.png',
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb24.png',
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb25.png',
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb26.png',
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb27.png',
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb28.png',
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb29.png',
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb30.png',
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb31.png',
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb32.png',
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb33.png',
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb34.png',
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb35.png',
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb36.png',
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb37.png',
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb38.png',
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb39.png',
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb40.png',
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb41.png',
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb42.png',
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb43.png',
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb44.png',
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb45.png',
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb46.png',
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb47.png',
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb48.png',
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb49.png',
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb50.png',
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb51.png',
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb52.png',
          },
        ],
        iconIndex: 0,

        text: [{
            name: '基础设计',
            path: '/ApprovalFormEdit'
          },
          {
            name: '表单设计',
            path: '/ApprovalFormForm'
          },
        ],
        moren: '0',

        // 选择人员弹出框
        dialogVisible: false,
        activeNames: null,
        checkop: [],
        drtjData: [],

        // 企业部门
        itemList: [],
        // 模板导出弹框
        dialogVisible: false,

        approvalName: null,
        grouping: '',
        approvalInstructions: null,
        approvalManagement: null,
        options: [{
          flow_group_name: '公共表单',
          flow_group_id: '1'
        }],
      }
    },
    watch: {
      // drtjData(newval,oldval){
      //   let newData = JSON.parse(sessionStorage.getItem('drtjData'));
      //   newData = newval;
      //   sessionStorage.setItem('drtjData',JSON.stringify(newData));
      //   console.log(newData)
      // },

      checkop(newval, oldval) {
        let newData = JSON.parse(sessionStorage.getItem('newPublicForm'))
        if (newData.manager) {
          console.log(newData, JSON.stringify(newval))
          newData.manager = JSON.stringify(newval)
          sessionStorage.setItem('newPublicForm', JSON.stringify(newData))
          console.log(newData)
        }
      },
      approvalName(newval, oldval) {

        let newData = JSON.parse(sessionStorage.getItem('newPublicForm'))
        newData.flow_name = newval
        sessionStorage.setItem('newPublicForm', JSON.stringify(newData))
        console.log(sessionStorage.getItem('newPublicForm'))
      },
      grouping(newval, oldval) {
        let newData = JSON.parse(sessionStorage.getItem('newPublicForm'))
        newData.flow_group_id = newval
        sessionStorage.setItem('newPublicForm', JSON.stringify(newData))
        console.log(sessionStorage.getItem('newPublicForm'), this.options)
      },
      iconUrl(newval, oldval) {
        let newData = JSON.parse(sessionStorage.getItem('newPublicForm'))
        newData.logo_url = newval
        sessionStorage.setItem('newPublicForm', JSON.stringify(newData))
        console.log(sessionStorage.getItem('newPublicForm'))
      },
      approvalInstructions(newval, oldval) {
        let newData = JSON.parse(sessionStorage.getItem('newPublicForm'))
        newData.flow_desc = newval
        sessionStorage.setItem('newPublicForm', JSON.stringify(newData))
        console.log(sessionStorage.getItem('newPublicForm'))
      },
    },
    created() {
      //
      console.log(this.$store.state.rndid)
      var formType = sessionStorage.getItem('formType')
      var newPublicForm = sessionStorage.getItem('newPublicForm') ?
        JSON.parse(sessionStorage.getItem('newPublicForm')) :
        []
      //
      var editData = sessionStorage.getItem('editData') ?
        JSON.parse(sessionStorage.getItem('editData')) :
        {
          flow: []
        }
      console.log(editData, newPublicForm)
      this.approvalName = editData.flow.flow_name;
      this.grouping = editData.flow.flow_group_id;
      this.approvalInstructions = editData.flow.flow_desc;
      this.iconUrl = newPublicForm.logo_url;
      if (newPublicForm.manager && newPublicForm.manager.length) {
        this.checkop =
          newPublicForm.manager == undefined ?
          [] :
          JSON.parse(newPublicForm.manager)
      } else {
        this.checkop = []
      }
      console.log(newPublicForm, editData)

    },
    methods: {
      deketeSess() {
        sessionStorage.removeItem('xinjianType')
        sessionStorage.removeItem('mlbb_one_shezhi')
        sessionStorage.removeItem('mlbb_four_shezhi')
        sessionStorage.removeItem('mlbb_biaodan_sheji')
        sessionStorage.removeItem('mlbb_shenpi_zhengyi')
        sessionStorage.removeItem('mlbb_liucheng_shezhi')
        sessionStorage.removeItem('mlbb_liucheng_shezhi_list')
        sessionStorage.removeItem('mlbb_liucheng_shezhi_tableId')
        sessionStorage.removeItem('mlbb_liucheng_shezhi_flowPermission')
      },
      holdFun() {
        var newPublicForm = JSON.parse(sessionStorage.getItem('newPublicForm'))
        console.log(newPublicForm)
        //公共表单
        var gongshi_baocuo = false
        const bitian_zhi = []
        const cunchu_data =
          this.$bendi_naqu_cunchu('mlbb_biaodan_sheji', '暂时') != undefined ?
          this.$bendi_naqu_cunchu('mlbb_biaodan_sheji', '暂时') :
          []
        console.log(this.$bendi_naqu_cunchu('mlbb_biaodan_sheji', '暂时'))
        for (let sort = 0; sort < cunchu_data.length; sort++) {
          console.log(sort)
          cunchu_data[sort].sort = sort + ''
          console.log(cunchu_data)

          if (cunchu_data[sort].components != undefined) {
            var components = cunchu_data[sort].components
            if (!Array.isArray(components)) {
              components = JSON.parse(components)
            }
            for (let nei_sort = 0; nei_sort < components.length; nei_sort++) {
              components[nei_sort].sort = nei_sort
            }
          }
          if (cunchu_data[sort].defaultImportant) {
            bitian_zhi.push('11111')
          }
        }

        if (cunchu_data && !bitian_zhi) {
          this.moren = 1
          console.log(222)
          this.$message({
            message: '表单必须有一个控件为必填值',
            type: 'warning',
          })
          return
        } else {
          if (!gongshi_baocuo) {
            var flow_id = 'asdffw'; // 更改flow_id
            if (sessionStorage.getItem('formType') == 'newPublic') {
              // 新建

              if (
                newPublicForm.logo_url &&
                newPublicForm.flow_group_id
              ) {
                console.log(cunchu_data);

                // newPublicForm.rel_table = ''
                insert_flow_info_public({
                  data: newPublicForm,
                }).then((res) => {
                  console.log(res)
                  if (res.code == 200) {
                    const param = {
                      ent_id: 'public',
                      flow_id,
                      list: cunchu_data,
                    }
                    console.log(param, '----');
                    insert_flow_form_info({
                      data: param,
                    }).then((res) => {
                      console.log(res)
                      if (res.code == 200) {
                        console.log(res, newPublicForm.flow_id)
                        this.$message({
                          message: '保存成功',
                          type: 'success',
                        })

                        this.$store.state.rndid.push(
                          sessionStorage.getItem('randomStrId')
                        )
                        console.log(
                          sessionStorage.getItem('randomStrId'),
                          this.$store.state.rndid
                        )
                        this.deketeSess()
                        // window.location.href = '/ApprovalForm'
                        this.$router.back()
                      }
                    })
                  }
                })
              } else {
                this.$message({
                  message: '必填项不能为空',
                  type: 'warning',
                })
              }
            }
            if (sessionStorage.getItem('formType') == 'edit') {
              //保存
              var editData = JSON.parse(sessionStorage.getItem('editData'));
              console.log(editData, newPublicForm)
              // var newPublicForm = JSON.parse(sessionStorage.getItem('newPublicForm'))
              if (editData.flow.flow_group_id) {


                let param = {
                  ent_id: 'public',
                  ent: editData.flow.ent_id,
                  flow_id: editData.flow.flow_id,
                  flow_name: editData.flow.flow_name,
                  flow_desc: editData.flow.flow_desc ? editData.flow.flow_desc : '',
                  flow_group_id: editData.flow.flow_group_id,
                  manager: editData.flow.manager,
                  logo_url: editData.flow.logo_url,
                }
                
                insert_flow_info_public({
                  data: param,
                }).then((res) => {
                  console.log(res)
                  if (res.code == 200) {
                    console.log(res, editData)
                    insert_flow_form_info({
                      data: {
                        ent_id: editData.flow.ent_id,
                        flow_id: editData.flow.flow_id,
                        list: cunchu_data,
                      },
                    }).then((res) => {
                      console.log(res, cunchu_data)
                      if (res.code == 200) {
                        this.$message({
                          message: '保存成功',
                          type: 'success',
                        })
                        this.deketeSess()
                        this.$router.go(-1)

                      }
                    })
                  }
                })
              } else {
                console.log(editData)
                this.$message({
                  message: '必填项不能为空',
                  type: 'warning',
                })
              }
            }
          }
        }
      },
      iconFun(index) {
        this.iconUrl = this.icons[index].value
      },
      getdefault() {
        // query_form_replace_write({ data: { ent_id }}).then(res => {
        //   console.log(res)
        //   this.drtjData = res.body.data;
        //   this.checkop = res.body.data.map(x => x.label);
        // })
      },
      changeDialog(val) {
        this.dialogVisible = false
        this.checkop = val.map((x) => x.label)
      },
      tabTitle(index) {
        this.moren = index
      },
    },
  }
</script>