  <template>
      <div class="wf-panel wf-widgetspanel">
          <div class="wf-panel-tab">
              <a href="javascript:;" style="margin-left: 10px;" class="tabitem current">控件</a>
          </div>
          <div class="wf-panel-body">
                <div
                  v-for="(item,index) in components"
                  class="wf-widgetsitem"
                  v-bind:data-index="index"
                  v-bind:data-type="item.componentName"
                  :key="index"
                  v-on:mousedown="start">
                  <label>
                      {{item.name}}
                  </label>
                  <i class="widgeticon" v-bind:class="item.componentName"></i>
              </div>
          </div>
      </div>
</template>
  <script type="text/ecmascript-6">
  export default {
    name: 'Mainleft',
    data() {
      return {
        components: [
          {
            name: '单行输入框',
            defaultLable: '单行输入框',
            defaultProps: '请输入',
            defaultImportant: false,
            siyou: '',
            guanbi: '11',
            defaultPrint: false,
            defaultEnable: false,
            componentName: 'textfield',
            supportSetting: ['label', 'placeholder1', 'required', 'important', 'Enable'],
            sort: ''
          },
          {
            name: '多行输入框',
            defaultLable: '多行输入框',
            defaultProps: '请输入',
            siyou: '',
            guanbi: '11',
            defaultImportant: false,
            defaultPrint: false,
            defaultEnable: false,
            componentName: 'textareafield',
            supportSetting: ['label', 'placeholder1', 'required', 'important', 'Enable'],
            sort: ''
          },
          {
            name: '单选框',
            defaultLable: '单选框',
            defaultProps: '请选择',
            siyou: '',
            guanbi: '11',
            defaultImportant: false,
            zidingyi: '0',
            defaultSync: false,
            defaultPrint: false,
            defaultEnable: false,
            defaultOptions: [
              { idx: 1, text: '选项1' },
              { idx: 2, text: '选项2' },
              { idx: 3, text: '选项3' }
            ],
            componentName: 'ddselectfield',
            supportSetting: ['label', 'placeholder1', 'options', 'required', 'important', 'Enable'],
            sort: ''
          },
          {
            name: '多选框',
            defaultLable: '多选框',
            siyou: '',
            guanbi: '11',
            zidingyi: '0',
            defaultProps: '请选择',
            defaultImportant: false,
            defaultPrint: false,
            defaultEnable: false,
            defaultOptions: [
              { idx: 1, text: '选项1', zhi: false, SettlementMethod: null, PrOfSettlement: 0, Settlement_amt: 0 },
              { idx: 2, text: '选项2', zhi: false, SettlementMethod: null, PrOfSettlement: 0, Settlement_amt: 0 },
              { idx: 3, text: '选项3', zhi: false, SettlementMethod: null, PrOfSettlement: 0, Settlement_amt: 0 }
            ],
            componentName: 'ddmultiselectfield',
            supportSetting: ['label', 'placeholder1', 'options', 'required', 'important', 'Enable'],
            sort: ''
          },
          {
            name: '日期',
            defaultLable: '日期',
            defaultProps: '请选择',
            defaultImportant: false,
            siyou: '',
            guanbi: '11',
            defaultPrint: false,
            defaultEnable: false,
            defaultFormat: 'yyyy-MM-dd',
            componentName: 'dddatefield',
            supportSetting: ['label', 'placeholder1', 'dateformat', 'required', 'important', 'Enable'],
            sort: ''
          },
          {
            name: '日期区间',
            defaultLable: '时间区间',
            defaultLable1: '开始时间',
            defaultLable2: '结束时间',
            siyou: '',
            guanbi: '11',
            defaultProps1: '请选择',
            defaultProps2: '请选择',
            defaultImportant: false,
            defaultPrint: false,
            defaultEnable: false,
            defaultFormat: 'yyyy-MM-dd',
            componentName: 'dddaterangefield',
            supportSetting: [
              'Enable',
              'label',
              'label1',
              'label2',
              'placeholder',
              'placeholder1',
              'placeholder2',
              'dateformat',
              'required',
              'important',
              'subtitle'],
            sort: ''
          },
          {
            name: '明细',
            defaultLable: '明细',
            defaultAction: '增加明细',
            siyou: '',
            guanbi: '11',
            components: [],
            selected: null,
            defaultPrint: false,
            defaultEnable: false,
            InTableCanvas: null,
            componentName: 'tablefield',
            supportSetting: ['label', 'required', 'Enable'],
            sort: ''
          },
          {
            name: '附件',
            defaultLable: '附件',
            siyou: '',
            guanbi: '11',
            defaultImportant: false,
            defaultPrint: false,
            componentName: 'ddattachment',
            defaultEnable: false,
            supportSetting: ['label', 'required', 'important', 'Enable'],
            sort: ''
          },
          {
            name: '图片',
            defaultLable: '图片',
            siyou: '',
            guanbi: '11',
            defaultImportant: false,
            defaultPrint: false,
            componentName: 'ddphotofield',
            defaultEnable: false,
            supportSetting: ['label', 'required', 'important', 'Enable'],
            sort: ''
          },
          {
            name: '说明文字',
            defaultLable: '说明文字',
            siyou: '',
            guanbi: '11',
            defaultImportant: false,
            defaultProps: '请输入说明文字',
            defaultShow: false,
            defaultPrint: false,
            defaultEnable: false,
            componentName: 'textnote',
            supportSetting: ['textnote', 'required', 'show', 'important', 'Enable'],
            sort: ''
          },
          {
            name: '部门',
            siyou: '',
            guanbi: '11',
            defaultLable: '部门',
            defaultProps: '请选择',
            defaultPrint: false,
            defaultImportant: false,
            componentName: 'department',
            defaultEnable: false,
            supportSetting: ['label', 'placeholder1', 'required', 'important', 'Enable'],
            sort: ''
          },
          {
            name: '联系人',
            defaultLable: '联系人',
            siyou: '',
            guanbi: '11',
            defaultProps: '请选择',
            defaultPrint: false,
            defaultImportant: false,
            componentName: 'contacts',
            defaultEnable: false,
            supportSetting: ['label', 'placeholder1', 'important', 'radio3', 'Enable'],
            sort: ''
          },
          {
            name: '客户',
            defaultLable: '客户',
            siyou: '',
            guanbi: '11',
            defaultProps: '请选择',
            defaultPrint: false,
            defaultImportant: false,
            componentName: 'cust',
            defaultEnable: false,
            supportSetting: ['label', 'important', 'Enable'],
            sort: ''
          },
          {
            name: '省市区',
            defaultLable: '省市区',
            siyou: '',
            guanbi: '11',
            defaultProps: '请选择',
            defaultPrint: false,
            defaultImportant: false,
            componentName: 'province',
            defaultEnable: false,
            supportSetting: ['label', 'important', 'Enable'],
            sort: ''
          },
          { // 计算公式
            name: '计算公式',
            defaultLable: '计算公式',
            siyou: '',
            guanbi: '11',
            defaultProps: '请选择',
            gongshi: '',
            defaultPrint: false,
            defaultImportant: false,
            componentName: 'calcform',
            defaultEnable: false,
            supportSetting: ['label', 'placeholder1', 'required', 'translate', 'gongshi2', 'Enable'],
            sort: ''
          },
          {
            name: '数字输入框',
            defaultLable: '数字输入框',
            siyou: '',
            guanbi: '11',
            defaultProps: '请输入',
            defaultImportant: false,
            defaultPrint: false,
            componentName: 'numberfield',
            defaultEnable: false,
            supportSetting: ['label', 'placeholder1', 'required', 'important', 'uint', 'Enable'],
            sort: ''
          },
          {
            name: '金额',
            defaultLable: '金额（元）',
            siyou: '',
            guanbi: '11',
            defaultProps: '请输入',
            defaultImportant: false,
            defaultTranslate: false,
            defaultPrint: false,
            defaultEnable: false,
            componentName: 'moneyfield',
            supportSetting: ['label', 'placeholder1', 'translate', 'required', 'important', 'Enable'],
            sort: ''
          }
        ]
      }
    },
    created() {
      let zhanshi_tubiao = true
      const biaodan_id = (this.$bendi_naqu_cunchu('mlbb_shenpi_zhengyi', '暂时') != undefined && this.$bendi_naqu_cunchu('mlbb_shenpi_zhengyi', '暂时').flow_id != undefined) ? this.$bendi_naqu_cunchu('mlbb_shenpi_zhengyi', '暂时').flow_id : ''

      if (this.$store.state.rndid.indexOf(biaodan_id) > -1) {
        zhanshi_tubiao = false
      } else {
        zhanshi_tubiao = true
      }
      if (!zhanshi_tubiao) {
        for (let i = 0; i < this.components.length; i++) {
          if (this.components[i].componentName == 'department' || this.components[i].componentName == 'contacts' || this.components[i].componentName == 'cust') {
            this.components.splice(i, 1)
            i = i - 1
          }
        }
      }
    },
    methods: {
      formTypeFun(params) {
        return this.$store.state.rndid.indexOf(params) > -1 ? true : false;
      },
      start: function(e) {
        const obj = {}
        const dom = e.currentTarget
        const index = dom.getAttribute('data-index')
        let actualLeft = dom.offsetLeft
        let current = dom.offsetParent
        let actualTop = dom.offsetTop
        while (current !== null) {
          actualLeft += current.offsetLeft
          actualTop += current.offsetTop
          current = current.offsetParent
        }
        obj.componentName = dom.getAttribute('data-type')
        obj.componentText = dom.querySelector('label').innerText
        obj.clientX = e.clientX
        obj.clientY = e.clientY
        obj.isstart = true
        obj.componentView = this.components[index]
        console.log(obj)
        drag.$emit('movestart', obj)
      }
    }
  }
  </script>

  <style scoped lang="scss">
  @import './design.css';
  </style>
